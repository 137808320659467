import { graphql } from "gatsby"
import React, { Fragment } from "react"
import PropTypes from "prop-types"
import Plx from "react-plx"
import windowSize from "react-window-size"
import { MdArrowForward } from "react-icons/md"
import hex2rgba from "hex2rgba"
import { Link } from "gatsby"

import Layout from "../../layouts"
import Container from "../../components/container"
import HeroContainer from "../../components/containers/hero"
import SEO from "../../components/seo"
import Header from "../../components/header"
import Footer from "../../components/footer"
import ContentBlock from "../../components/content-block"

import gatsbyDaysLogo from "!raw-loader!svgo-loader?{ 'plugins': [{ 'collapseGroups': false }, { 'removeTitle': false }, { 'cleanupIDs': false }] }!../../assets/gatsby-days-logo.svg"
import Metaball from "../../components/metaball"

import {
  mediaQueries,
  radii,
  colors,
  fonts,
  fontSizes,
  letterSpacings,
  space,
} from "../../utils/presets"
import { rhythm } from "../../utils/typography"

const styles = {
  sectionHeader: {
    color: colors.lilac,
    fontFamily: fonts.header,
    fontSize: fontSizes[1],
    textTransform: `uppercase`,
    letterSpacing: letterSpacings.tracked,
    fontWeight: `normal`,
    marginTop: space[9],
  },
  signupLink: {
    background: colors.gatsby,
    border: `1px solid ${hex2rgba(colors.howItWorks.bright, 0.275)}`,
    borderRadius: radii[2],
    color: colors.white,
    display: `inline-block`,
    fontWeight: `bold`,
    marginTop: space[3],
    marginBottom: space[12],
    padding: `${space[2]} ${space[5]}`,
    textDecoration: `none`,
    whiteSpace: `nowrap`,
    [mediaQueries.desktop]: {
      fontSize: fontSizes[3],
    },
  },
  gatsbyDaysLogo: {
    maxWidth: rhythm(8),
  },
}

class GatsbyDaysListPage extends React.Component {
  render() {
    const { contentfulPage, nav } = this.props.data
    const { contentBlocks, eventSections, quickLinks } = contentfulPage
    const [{ url: ctaUrl, displayTitle: ctaLabel }] = quickLinks
    const [LandingPageIntro, CoomingEventInfo] = contentBlocks

    const clientHeight = this.props.windowHeight || 1

    return (
      <Layout pathname={this.props.location.pathname}>
        <SEO contentfulPage={contentfulPage} />
        <Header navItems={nav.edges} />
        <div css={{ position: `relative`, width: `100%` }}>
          <Plx
            animateWhenNotInViewport
            parallaxData={[
              {
                duration: clientHeight * 1.5,
                offset: -clientHeight,
                properties: [
                  { endValue: 500, property: `translateY`, startValue: 0 },
                  { endValue: 0, property: `opacity`, startValue: 1 },
                ],
                start: `self`,
              },
            ]}
          />
          <Plx
            animateWhenNotInViewport
            parallaxData={[
              {
                duration: clientHeight * 1.5,
                offset: -clientHeight,
                properties: [
                  {
                    endValue: 200,
                    property: `translateY`,
                    startValue: 0,
                  },
                ],
                start: `self`,
              },
            ]}
          >
            <Metaball />
          </Plx>
          <HeroContainer>
            <Container>
              <div css={{ position: `relative`, zIndex: 100, width: `100%` }}>
                <Plx
                  animateWhenNotInViewport
                  parallaxData={[
                    {
                      duration: clientHeight * 1.5,
                      offset: -clientHeight,
                      properties: [
                        {
                          endValue: -50,
                          property: `translateY`,
                          startValue: 0,
                        },
                      ],
                      start: `self`,
                    },
                  ]}
                >
                  <div
                    css={{
                      display: `flex`,
                      flexWrap: `wrap`,
                      width: `100%`,
                      [mediaQueries.tablet]: { flexWrap: `nowrap` },
                    }}
                  >
                    <div
                      css={{
                        marginRight: space[8],
                        minWidth: `250px`,
                        width: `20%`,
                      }}
                    >
                      <h1
                        dangerouslySetInnerHTML={{ __html: gatsbyDaysLogo }}
                        css={{
                          ...styles.gatsbyDaysLogo,
                          display: `block`,
                          svg: {
                            width: `100%`,
                            height: `auto`,
                          },
                        }}
                      />
                    </div>

                    <div css={{ marginTop: space[3], maxWidth: `680px` }}>
                      {LandingPageIntro && (
                        <ContentBlock contentBlock={LandingPageIntro} />
                      )}

                      <h2 css={styles.sectionHeader}>Upcoming</h2>

                      {CoomingEventInfo && (
                        <div
                          css={{
                            h2: {
                              fontSize: fontSizes[5],
                              marginBottom: space[1],
                            },
                            h4: {
                              fontWeight: `normal`,
                              color: colors.gray.calm,
                            },
                          }}
                        >
                          <ContentBlock contentBlock={CoomingEventInfo} />
                        </div>
                      )}

                      {ctaUrl && (
                        <a href={ctaUrl} css={styles.signupLink}>
                          {ctaLabel}
                          {` `}
                          <MdArrowForward
                            css={{
                              marginLeft: `.2em`,
                              verticalAlign: `middle`,
                            }}
                          />
                        </a>
                      )}
                      <h2 css={styles.sectionHeader}>Past Gatsby Days</h2>
                    </div>
                  </div>
                </Plx>
              </div>
            </Container>
          </HeroContainer>
          <Container>
            {eventSections.map(event => (
              <Fragment key={event.id}>
                <section
                  css={{
                    display: `flex`,
                    marginTop: space[8],
                    marginBottom: space[8],

                    [mediaQueries.desktop]: {
                      "&::before": {
                        content: `" "`,
                        marginRight: space[8],
                        minWidth: `250px`,
                        width: `20%`,
                        flexShrink: 0,
                      },
                    },
                  }}
                >
                  <div
                    css={{
                      maxWidth: `680px`,
                      h3: {
                        color: colors.lilac,
                        fontSize: fontSizes[5],
                        strong: {
                          color: colors.gray.dark,
                          display: `block`,
                          fontSize: fontSizes[2],
                          marginBottom: space[1],
                        },
                      },
                    }}
                    dangerouslySetInnerHTML={{
                      __html: event.description.childMarkdownRemark.html,
                    }}
                  />
                </section>
                <section>
                  <div css={styles.container}>
                    <ul
                      css={{
                        margin: 0,
                        listStyle: `none`,
                        [mediaQueries.tablet]: {
                          display: `flex`,
                          flexWrap: `wrap`,
                          justifyContent: `space-between`,
                        },
                      }}
                    >
                      {event.videos.map(video => {
                        const { page } = video
                        const { title, contentBlocks, slug } = page[0]
                        const contentBlock = contentBlocks[0]

                        return (
                          <li
                            key={contentBlock.id}
                            css={{
                              flex: `0 0 48%`,
                              [mediaQueries.desktop]: {
                                flex: `0 0 31%`,
                                width: `100%`,
                              },
                            }}
                          >
                            <Link
                              to={`/${slug}`}
                              title="Permalink"
                              css={{
                                display: `block`,
                                "&&": {
                                  marginBottom: 40,
                                },
                                textDecoration: `none`,
                              }}
                            >
                              <div>
                                <ContentBlock contentBlock={contentBlock} />
                              </div>

                              <div
                                css={{
                                  marginTop: 8,
                                  fontWeight: `bold`,
                                }}
                              >
                                {title}
                              </div>
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </section>
              </Fragment>
            ))}
          </Container>
        </div>
        <Footer navItems={nav.edges} />
      </Layout>
    )
  }
}

GatsbyDaysListPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  dimensions: PropTypes.object,
  windowHeight: PropTypes.number,
}

export default windowSize(GatsbyDaysListPage)

export const pageQuery = graphql`
  query ContentfulGatsbyDaysLandingPage2($id: String!) {
    ...MainNavigation
    contentfulPage(id: { eq: $id }) {
      name
      title
      subtitle {
        subtitle
      }
      ...ContentBlocks
      ...SocialMediaImage
      eventSections {
        id
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        videos {
          page {
            title
            name
            slug
            ...ContentBlocks
          }
        }
      }
      quickLinks {
        displayTitle
        url
      }
    }
  }
`
