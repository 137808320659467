import { Link } from "gatsby"
import React from "react"
import PropTypes from "prop-types"
import kebabCase from "lodash/kebabCase"

import { getLink } from "../utils/navigation"
import { colors, fontSizes, space } from "../utils/presets"

const Job = ({ job }) => (
  <div css={{ marginBottom: space[10] }}>
    <h3 id={kebabCase(job.jobTitle)} css={{ marginBottom: space[3] }}>
      <Link
        to={getLink(job.jobPage)}
        css={{
          "&&": { fontWeight: `bold`, "&:hover": { color: colors.gatsby } },
        }}
      >
        {job.jobTitle}
      </Link>
    </h3>
    <div
      css={{
        fontSize: fontSizes[1],
        color: colors.gray.neutral,
      }}
      dangerouslySetInnerHTML={{
        __html:
          job.jobDescription && job.jobDescription.childMarkdownRemark.html,
      }}
    />
  </div>
)

Job.propTypes = {
  job: PropTypes.array.isRequired,
  idx: PropTypes.number,
}

export default Job
