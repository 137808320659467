import { graphql } from "gatsby"
import React from "react"
import PropTypes from "prop-types"
import kebabCase from "lodash/kebabCase"

import Img from "../components/core/img"
import { FaTwitter, FaGithub, FaGlobe } from "react-icons/fa"

import {
  colors,
  fonts,
  fontSizes,
  lineHeights,
  mediaQueries,
  transition,
  radii,
  space,
} from "../utils/presets"
import { linkStyles } from "../utils/styles"

const externalLinkStyles = {
  verticalAlign: `text-bottom`,
  marginRight: space[1],
}
const socialMediaIcons = {
  twitter: <FaTwitter css={externalLinkStyles} />,
  github: <FaGithub css={externalLinkStyles} />,
  website: <FaGlobe css={externalLinkStyles} />,
}

const ExternalLinkWithIcon = ({ to, icon }) => (
  <a
    href={to}
    css={{
      color: colors.lilac,
      marginRight: space[1],
      transition: `all ${transition.speed.fast} ${transition.curve.default}`,
      "&:hover": {
        opacity: 1,
        color: colors.gatsby,
      },
    }}
  >
    {socialMediaIcons[icon]}
  </a>
)

ExternalLinkWithIcon.propTypes = {
  to: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
}

const breakpoint = mediaQueries.mobile

const Profiles = ({ profiles }) => (
  <>
    {profiles.map((profile, i) => (
      <div
        key={i}
        css={{
          position: `relative`,
          marginBottom: space[8],
          textAlign: `center`,
          width: `100%`,
          [breakpoint]: {
            display: `flex`,
            textAlign: `left`,
          },
          [mediaQueries.tablet]: {
            flex: `0 0 45%`,
          },
        }}
      >
        <div>
          {profile.hoverPhoto && (
            <Img
              css={{
                display: `none !important`,
                position: `absolute !important`,
                borderRadius: radii[6],
                marginBottom: space[4],
                transform: `translateZ(0)`,
                [breakpoint]: {
                  marginBottom: 0,
                  display: `block !important`,
                },
              }}
              fixed={profile.hoverPhoto.fixed}
            />
          )}
          {profile.photo && (
            <Img
              css={{
                borderRadius: radii[6],
                marginBottom: space[4],
                transform: `translateZ(0)`,
                [breakpoint]: {
                  marginBottom: 0,
                  ...(profile.hoverPhoto
                    ? {
                        ":hover": {
                          opacity: `0 !important`,
                        },
                      }
                    : {}),
                },
              }}
              fixed={profile.photo.fixed}
            />
          )}
        </div>
        <div
          css={{
            [breakpoint]: { paddingLeft: space[6] },
          }}
        >
          <div>
            <h3 id={kebabCase(profile.name)} css={{ marginBottom: space[3] }}>
              {profile.name}
            </h3>
            <h4
              css={{
                color: colors.gray.neutral,
                marginBottom: 0,
                fontWeight: 500,
                fontSize: fontSizes[1],
                lineHeight: lineHeights.dense,
                fontFamily: fonts.system,
              }}
            >
              {profile.title}
            </h4>
          </div>
          <div
            css={{
              marginBottom: space[6],
            }}
          >
            {profile.twitter && (
              <a
                href={`https://twitter.com/${profile.twitter}`}
                css={{
                  color: colors.gatsby,
                  fontSize: fontSizes[1],
                  fontWeight: 500,
                  marginRight: space[2],
                  textDecoration: `none`,
                  transition: `all ${transition.speed.fast} ${
                    transition.curve.default
                  }`,
                  "&:hover": {
                    color: colors.gatsby,
                  },
                }}
              >
                @{profile.twitter}
              </a>
            )}
            {profile.gitHub && (
              <ExternalLinkWithIcon to={profile.gitHub} icon="github" />
            )}
            {profile.website && (
              <ExternalLinkWithIcon to={profile.website} icon="website" />
            )}
          </div>
          <div
            css={{
              color: colors.gray.neutral,
              fontSize: fontSizes[1],
              lineHeight: lineHeights.loose,
              "& a": {
                ...linkStyles,
              },
            }}
            dangerouslySetInnerHTML={{
              __html: profile.bio && profile.bio.childMarkdownRemark.html,
            }}
          />
        </div>
      </div>
    ))}
  </>
)

Profiles.propTypes = {
  profiles: PropTypes.array.isRequired,
}

export default Profiles

export const query = graphql`
  fragment ProfilesFragment on ContentfulProfile {
    name
    title
    photo {
      fixed(width: 80, height: 80, quality: 90) {
        ...GatsbyContentfulFixed
      }
    }
    hoverPhoto {
      fixed(width: 80, height: 80, quality: 90) {
        ...GatsbyContentfulFixed
      }
    }
    twitter
    gitHub
    website
    bio {
      childMarkdownRemark {
        html
      }
    }
  }
`
